@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
:root {
	--primary-blue: #977029;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Lora", serif;
}

.container {
	width: 95%;

	@media screen and (min-width: 900px) {
		width: 85%;
		margin: 0 auto;
	}
}
